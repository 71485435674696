import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import scrollTo from 'gatsby-plugin-smoothscroll'
import Hero from "../components/hero"
import ArrowRight from "../components/svg/arrowRight"
import Seo from "../components/seo"
import {StaticImage} from "gatsby-plugin-image"
import Testimonial from "../components/testimonial"
import Cta from "../components/cta"

const OxPage = () => (
    <Layout>
        <Seo 
            title="Legal Tech startup development case study" 
            description="Case study of the legal tech startup design and development work completed for this Canberra based conveyancing startup."
        />

        <Hero
            h1="Legal tech startup development case study"  
            h2="Design &amp; Development of the OXRooms conveyancing platform"
        />

        
        <section className="bg-noise apos section-pad-sm">
            <div className="container-lg" id="anchor">
                <div className="row">
                    <StaticImage 
                        src="../images/oxrooms.png"
                        alt="Screens from the OX Rooms website and application"
                        formats={["AUTO", "WEBP", "AVIF"]}
                        className="porti-top-img"
                        placeholder="none"
                        backgroundcolor="white"
                        layout="fullWidth"
                        quality="100%"
                    />
                </div>
            </div>

        </section>
        
        <section className="section-pad-sm">
            <div className="container">
                <div className="row">
                    <div className="porti-wrapper">
                        <div className="cols">
                            <div className="col-26">
                                <div className="sticky-porti-menu">
                                    <button onClick={() => scrollTo('#bg')} className="btn">
                                        Background
                                    </button>
                                    <button onClick={() => scrollTo('#work')} className="btn">
                                        The work
                                    </button>
                                    <button onClick={() => scrollTo('#services')} className="btn">
                                        Services
                                    </button>
                                    {/* <button onClick={() => scrollTo('#testimonial')} className="btn">
                                        Testimonial
                                    </button> */}
                                    <button onClick={() => scrollTo('#project')} className="btn">
                                        View project
                                    </button>
                                </div>
                            </div>
                            <div className="col-67 long-text">
                                <div id="bg" className="porti-text-section">
                                    <h3>Background</h3>
                                    <p>OX Rooms is a legal tech startup based in Canberra on a mission to create efficiency in the conveyancing and property transaction process.</p>
                                </div>
                                <div id="work" className="porti-text-section">
                                    <h3>The work</h3>
                                    <p>Leveraging from the deep industry knowledge of the founders, Launch Lab helped to plan, design and develop the software.</p> 
                                    <p>The web application enables buyer, sellers, their legal respresentatives and estate agents to work together in a single online 'room' to complete property transactions.</p>
                                    <p>We then designed a marketing website and built it on Webflow.</p>
                                </div>
                                <div id="services" className="porti-text-section">
                                    <h4>Services</h4>
                                    <p>The web application was developed using AWS, Python and React.</p>
                                    <Link to="/startup-web-designers/" className="btn btn-lg btn-line">Web &amp; product design</Link>
                                    <Link to="/startup-web-application-development/" className="btn btn-lg btn-line">Startup application development</Link>
                                    <Link to="/webflow-developer/" className="btn btn-lg btn-line">Webflow development</Link>
                                </div>
                                <div id="testimonial" className="porti-text-section">
                                    <h4>Testimonial</h4>
                                    <Testimonial
                                        quote="Working with Shaun and Dave from Launch Lab on OX Rooms has been a fantastic experience! Choosing them to develop our platform and website was one of our best early decisions. After meeting with several development teams, it was clear that Launch Lab not only understood our vision but could also deliver a top-quality product. Launch Lab consistently delivers high-quality work and is responsive to any issues that arise. They communicate directly and don't waste time, keeping the scope on track while remaining flexible with adjustments and always bringing solutions—not problems—when challenges come up. Shaun and Dave have been invaluable partners, making it enjoyable and rewarding to work together on OX Rooms. I look forward to our ongoing collaboration and reaching new heights together! I highly recommend Launch Lab to anyone looking for a skilled and reliable development team!"
                                        name="Emmy Arthurson"
                                        title="Co-founder, OX Rooms"
                                    />
                                </div>
                                <div id="project" className="porti-text-section">
                                    <h5>View project</h5>
                                    <p>View the OX Rooms website.</p>
                                    <a href="https://www.oxrooms.com.au/" className="btn btn-lg btn-purple">View website</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="bg-purple with-pad">
            <div className="container-lg">
                <div className="row">
                <div className="work-jumbo-wrapper with-radius">
                        <StaticImage 
                            src="../images/launch-lab-work-2024.png"
                            alt="Screengrabs of some of the websites and startups that Launch Lab have designed and developed"
                            formats={["AUTO", "WEBP", "AVIF"]}
                            placeholder="none"
                            backgroundcolor="white"
                            layout="fullWidth"
                            quality="100%"
                            className="with-radius"
                        />
                </div>
                <div className="flex flex-jc">
                    <Link to="/portfolio/" className="btn btn-lg btn-pink">
                    <span className="flex flex-ac">
                        View all projects
                        <ArrowRight />
                    </span>
                    </Link>
                </div>
                </div>
            </div>
        </section>
        
        <Cta 
            title="Build your startup with trusted onshore Australian developers"
            text="Get an obligation free web design or web development quote from an experienced local Australian development team."
        />
    </Layout>
)

export default OxPage